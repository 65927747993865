import React from 'react'
import PropTypes from 'prop-types'
import Products from '../Products'
import { CrossSellPlaceholderList } from './CrossSellPlaceholderList'

export const isLoading = (loading, collection) => loading || (!loading && !collection)

const List = ({
  collection,
  loading,
  lang,
  format,
  onProductClick,
  sizes,
  count = 6,
  checkedProducts,
  onProductCheck,
  target,
  listProps,
  cardWrapperProps,
  showBVRatingOnListerCards
}) => {
  const loadingProps = {
    count,
    perRow: count
  }
  if (isLoading(loading, collection)) return <CrossSellPlaceholderList {...loadingProps} />

  if (collection.length === 0) return false

  return (
    <Products
      {...{
        collection,
        lang,
        format,
        onProductClick,
        sizes,
        checkedProducts,
        onProductCheck,
        target,
        listProps,
        cardWrapperProps,
        showBVRatingOnListerCards
      }}
    />
  )
}

List.propTypes = {
  target: PropTypes.string,
  listProps: PropTypes.object,
  cardWrapperProps: PropTypes.object
}

export default List
