import React from 'react'
import { Flex, Skeleton } from '@chakra-ui/react'

const sm = '@media screen and (max-width: 540px)'
const md = '@media screen and (min-width: 541px)'
const lg = '@media screen and (min-width: 768px)'

export const CrossSellPlaceholderList = ({ count }) => {
  return (
    <Flex
      gap='40px'
      sx={{
        [sm]: {
          p: '16px 15px',
          flexWrap: 'nowrap',
          flexDirection: 'column'
        },
        [md]: {
          p: '24px 15px',
          flexWrap: 'wrap'
        },
        [lg]: {
          flexWrap: 'nowrap'
        }
      }}
    >
      {[...Array(count).keys()].map(i => {
        return (
          <Flex
            key={`skelet-${i}`}
            sx={{
              [sm]: {
                h: '80px',
                flex: '0 1 auto'
              },
              [md]: {
                h: '265px',
                flex: '1 1 160px'
              },
              [lg]: {
                flex: '0 1 auto'
              }
            }}
            w='100%'
          >
            <Skeleton
              sx={{
                [sm]: {
                  d: 'flex'
                },
                [md]: {
                  d: 'none'
                }
              }}
              w='80px'
              h='80px'
              mr='15px'
            />
            <Flex
              sx={{
                [sm]: {
                  w: 'calc(100% - 95px)',
                  gap: '6px 0px'
                },
                [md]: {
                  w: '100%',
                  gap: '10px 0px'
                }
              }}
              flexDirection='column'
            >
              <Skeleton
                sx={{
                  [sm]: {
                    minH: '36px'
                  },
                  [md]: {
                    minH: '180px'
                  }
                }}
                w='100%'
              />
              <Skeleton
                sx={{
                  [sm]: {
                    minH: '16px'
                  },
                  [md]: {
                    minH: '35px'
                  }
                }}
                w='100%'
              />
              <Skeleton
                sx={{
                  [sm]: {
                    minH: '16px'
                  },
                  [md]: {
                    minH: '35px'
                  }
                }}
                w='100%'
              />
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}
