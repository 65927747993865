import { useFetch } from 'use-http'
import { getCrossSellProductsPath } from 'client/common/xhr/gateway'

export const EMPTY_RESPONSE_INTERCEPTOR = {
  interceptors: {
    response: async ({ response }) => {
      const res = response
      if (res.data && Object.keys(res.data).length === 0) {
        res.data = { products: { collection: [] } }
      }
      return res
    }
  }
}
export const getHeaders = ({ ipAddress }) => {
  return {
    headers: {
      ip: ipAddress
    }
  }
}

export const useCrossSellFetch = (props, options) => {
  return useFetch(getCrossSellProductsPath(props), { ...getHeaders(props), ...options })
}
